import React, { Component } from "react"
import _ from 'lodash';
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
// import BannerTopCTA from "../components/banner-top-cta"
import Img from "gatsby-image"
import SEO from "../components/seo"
import Breadcrumb from "../components/breadcrumb.js"
const { htmlToText } = require("html-to-text")
// var dictionary = require("../dic/dic.json")

class CategoryAll extends Component {
  // constructor(props) {
  //   super(props);
  // }
  render() {

    const posts = this.props.data.allWpPost.nodes
    const category = this.props.data.wpCategory;
    var main_cut_slug = category.slug;
    if (category.ancestors !== null) {
      main_cut_slug = category.ancestors.nodes[0].slug;
    }
    var nameCat;
    var cat;
    const page = this.props.data.wpPage
    const langPage = this.props.pageContext.idioma;
    var slugCategory = category.uri;
    if (slugCategory !== undefined) {
      slugCategory = slugCategory.replace("all/", "");
      slugCategory = slugCategory.replace("category/", "");
    }

    var openGraphslugPage = slugCategory
    const seo = page.seo;

    if (category.slug !== 'blog') {
      var breadcrumb = [];
      let categoria = {
        title: category.ancestors.nodes[0].name,
        slug: category.ancestors.nodes[0].slug
      }
      breadcrumb.push(categoria);
      categoria = {
        title: category.name,
        slug: null
      }
      breadcrumb.push(categoria);
    }
    return (
      <Layout currentPage={category.name} langPage={this.props.pageContext.idioma} clase_pagina={'page-blog page-blog-category'}>
        <SEO
          title={seo.title}
          description={seo.metaDesc}
          canonical={slugCategory}
          robotsNofollow={seo.metaRobotsNofollow}
          robotsNoindex={seo.metaRobotsNoindex}
          openGraphSite={seo.opengraphSiteName}
          openGraphType={seo.opengraphType}
          openGraphUrl={openGraphslugPage}
          openGraphImg={
            seo.opengraphImage !== null
              ? seo.opengraphImage.sourceUrl
              : "/images/logo-billage-default-social-share.jpg"
          }
          openGraphImgWidth={
            seo.opengraphImage !== null
              ? seo.opengraphImage.mediaDetails.width
              : 300
          }
          openGraphImgHeight={
            seo.opengraphImage !== null
              ? seo.opengraphImage.mediaDetails.height
              : 300
          }
          dctermsCreated={page.date}
          dctermsModified={page.modified}
          lang={langPage}
          twitterImg={
            seo.twitterImage !== null
              ? seo.twitterImage.sourceUrl
              : "/images/logo-billage-default-social-share.jpg"
          }
          twitterImgHeight={
            seo.twitterImage !== null ? seo.twitterImage.mediaDetails.height : 300
          }
          twitterImgWidth={
            seo.twitterImage !== null ? seo.twitterImage.mediaDetails.width : 300
          }

        />
        {/* <BannerTopCTA langPage={langPage} /> */}
        <Breadcrumb props={breadcrumb} isChild={category.slug === 'blog' ? "true" : "false"} isPage="false" />
        <div className="wrapper">
          <h1>{category.name}</h1>
          <div className="row post-list">
            {posts.map(post => {
              // var main_cat = null // not used
              var mainImage = null;
              if (post.featuredImage !== null &&
                post.featuredImage.node !== null &&
                post.featuredImage.node.localFile !== null) {
                mainImage = post.featuredImage.node.localFile.childImageSharp.fluid;
                mainImage.sizes = "(max-width: 540px) 100px,(min-width: 768px) and (max-width: 1200px) 400px, 600px";
              }

              var html = post.excerpt;
              html = html.replace("[", "")
              html = html.replace("]", "")
              var post_excerpt = htmlToText(html, {
                ignoreHref: true,
              })
              var post_excerpt = _.truncate(post_excerpt, {
                length: 170,
                separator: /,?\.* +/
              })
              return (
                <div key={post.databaseId} className="post-wrapper col-12 col-md-6 col-lg-4">
                  <article className="post-item">
                    {post.categories.nodes.map(categoria => {
                      if (categoria.ancestors !== null && categoria.databaseId !== 9525 && categoria.databaseId !== 9552 && categoria.databaseId !== 9528 && categoria.databaseId !== 9531 && categoria.databaseId !== 9534 && categoria.databaseId !== 9537 && categoria.databaseId !== 9549 && categoria.databaseId !== 9540 && categoria.databaseId !== 9543 && categoria.databaseId !== 9546) {
                        cat = categoria.slug
                        nameCat = categoria.name
                      } else {
                        // if(categoria.databaseId==102 || categoria.databaseId==99){
                        //   main_cat = categoria.slug // not used
                        // }
                      }
                      return true;
                    })}

                    <div className="header">
                      <Link to={`/${langPage}/${main_cut_slug}/${post.slug}`} className="overlay">
                        <span>Leer Más</span>
                      </Link>

                      {mainImage !== null ? (
                        <Img itemProp="image"
                          fluid={mainImage}
                          className="lazy header"
                          alt={post.featuredImage.node.altText}
                        />
                      ) : null}
                    </div>
                    <div className="inner">
                      <aside className="infopost">
                        <div className="author gravatar">
                          <img
                            src={post.author.node.avatar.url}
                            alt={post.author.node.name}
                            title={post.author.node.name}
                          />
                        </div>
                        <div className="metadata">
                          {nameCat}
                          <span>|</span>
                          {post.date}
                        </div>
                      </aside>
                      <h2 className="title"><Link to={`/${langPage}/${main_cut_slug}/${post.slug}`}>{post.title}</Link></h2>
                      <Link to={`/${langPage}/${main_cut_slug}/${post.slug}`}><p className="excerpt">{post_excerpt}</p></Link>
                    </div>
                  </article>
                </div>

              )
            })}
          </div>
        </div>
      </Layout>
    )
  }
}

export default CategoryAll

export const CategoryAllQuery = graphql`
  query currentCategoryAllQuery( $databaseId: Int!) {
    allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { databaseId: { eq: $databaseId } } }
        }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        databaseId
        title
        content
        excerpt
        slug
        date(formatString: "DD/MM/yyyy")
        seo {
          title
          metaDesc
        }
        author {
          node {
            name
            slug
            avatar {
              url
            }
          }
        }
        categories {
          nodes {
            databaseId
            name
            slug
            ancestors {
              nodes {
                databaseId
                slug
                uri
                name
              }
            }
          }
        }
        featuredImage {
          node {
            altText
            srcSet
            sourceUrl
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    wpCategory(databaseId: { eq: $databaseId }) {
      slug
      name
      uri
      ancestors {
        nodes {
          slug
          name
        }
      }
      seo {
        canonical
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        opengraphPublisher
        opengraphImage {
          altText
          sourceUrl
          title
          mediaDetails {
            height
            width
          }
        }
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          mediaDetails {
            height
            width
          }
          sourceUrl
        }
      }
    }
    wpPage(databaseId: { eq: 6518 }) {
      slug
      date
      uri
      modified
      locale {
        id
      }

      seo {
        canonical
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        opengraphPublisher
        opengraphImage {
          altText
          sourceUrl
          title
          mediaDetails {
            height
            width
          }
        }
        twitterDescription
        twitterTitle
        schema {
          pageType
        }
        twitterImage {
          altText
          mediaDetails {
            height
            width
          }
          sourceUrl
        }
      }
      page_content {
        contentRaw
      }
    }
  }
`
